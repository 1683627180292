import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "hooks/useSsrHooks";
import { getClientOrigin } from "utils/getClientOrigin";

export const getAemEnv = () => {
  const { AEM_DEV_ENV, AEM_STAGE_ENV } = useEnv();
  const originSSR = getClientOrigin();
  const originHost = processEnvServer ? originSSR : window.location.hostname;
  const aemEnv = originHost.indexOf("adobeaemcloud")
    ? originHost.indexOf(AEM_DEV_ENV) > -1
      ? "dev"
      : originHost.indexOf(AEM_STAGE_ENV)
      ? "stage"
      : "prod"
    : undefined;
  return aemEnv;
};

export default {
  getAemEnv
};
