import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type ItemsProps = Omit<SlingElementProps, "tagName">;

export type ItemsFC = React.FC<ItemsProps>;

export const Items: ItemsFC = ({ children, tagName }) => {
  return <SlingElement tagName={tagName}>{children}</SlingElement>;
};

Items.defaultProps = {
  tagName: "items"
};

export default Items;
