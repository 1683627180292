/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A text field component.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/textfield/index.html
 */
export type FormTextFieldProps = FormFieldProps & {
  /**
   * The name that identifies the field when submitting the form.
   */
  name?: string;
  /**
   * The value of the field.
   */
  value?: string;
  /**
   * A hint to the user of what can be entered in the field.
   * @attributes i18n
   */
  emptyText?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * Indicates if the value can be automatically completed by the browser.
   * See also MDN documentation regarding autocomplete attribute.
   */
  autocomplete?: string;
  /**
   * The autofocus attribute to lets you specify that the field should have input focus when the page loads,
   * unless the user overrides it, for example by typing in a different control.
   * Only one form element in a document can have the autofocus attribute.
   */
  autofocus?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * The maximum number of characters (in Unicode code points) that the user can enter.
   */
  maxlength?: number;
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormTextFieldFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormTextFieldProps
>;

export const FormTextField: FormTextFieldFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/textfield"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormTextField.defaultProps = {
  autocomplete: "off"
};
