import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type LayoutProps = Omit<SlingElementProps, "tagName"> & {
  type: string;
};

export type LayoutFC = React.FC<LayoutProps>;

export const Layout: LayoutFC = ({ children, tagName, type, ...rest }) => {
  return (
    <SlingElement {...rest} tagName={tagName} sling_resourceType={type}>
      {children}
    </SlingElement>
  );
};

Layout.defaultProps = {
  tagName: "layout"
};

export default Layout;
