import {
  FormSelectItem,
  FormSelectItemProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/select/FormSelectItem";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type DropDownItemProps = Omit<SlingElementProps, "tagName"> &
  FormSelectItemProps;

export type DropDownItemFC = React.FC<DropDownItemProps>;

export const DropDownItem: DropDownItemFC = ({ children, value, ...rest }) => {
  return (
    <FormSelectItem
      {...rest}
      tagName={rest["tagName"] || toTagName(`option-${value}`)}
      value={value}
    >
      {children}
    </FormSelectItem>
  );
};

DropDownItem.defaultProps = {};

export default DropDownItem;
