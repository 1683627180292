/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/foundation/form/field/FormField";
import { RenderConditionProps } from "aem/ui/authoring/granite/RenderCondition";

/**
 * A hidden field component.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/foundation/form/hidden/index.html
 */
export type FormHiddenProps = FormFieldProps &
  RenderConditionProps & {
    /**
     * The id attribute.
     */
    id?: string;
    /**
     * The class attribute. This is used to indicate the semantic relationship of the component similar to rel attribute.
     */
    rel?: string;
    /**
     * The class attribute.
     */
    class?: string;
    /**
     * The title attribute.
     * @attributes i18n
     */
    title?: string;
    /**
     * The name that identifies the field when submitting the form.
     */
    name?: string;
    /**
     * Indicates if the field is in disabled state.
     */
    disabled?: boolean;
    /**
     * The value of the field.
     */
    value?: string;
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type FormHiddenFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormHiddenProps
>;

export const FormHidden: FormHiddenFC = ({ tagName, children, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/foundation/form/hidden"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormHidden.defaultProps = {};
