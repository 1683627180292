import React from "react";
import { AEMComponentProps } from "@RHCommerceDev/aem/types";

export type AEMCommonHTMLAttributes = {
  className?: string;
  "data-rh-effect"?: string;
  "data-rh-uid"?: string;
};

export function getAEMCommonHTMLAttributes<P extends AEMComponentProps>(
  props: React.PropsWithChildren<P>
): AEMCommonHTMLAttributes {
  const aemCommonHTMLAttributes: AEMCommonHTMLAttributes = {};

  if (props.rhEffect) {
    aemCommonHTMLAttributes["data-rh-effect"] = props.rhEffect.toString();
  }

  if (props.rhEffect || props.rhEffectEntrance || props.rhEffectExit) {
    aemCommonHTMLAttributes.className = "rh-invisible";
  }

  if (props.rhUid) {
    aemCommonHTMLAttributes["data-rh-uid"] = props.rhUid;
  }

  return aemCommonHTMLAttributes;
}
