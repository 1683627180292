import { HideResource } from "aem/ui/authoring/rh/HideResource";
import {
  TabbedDialog,
  TabbedDialogProps
} from "aem/ui/authoring/rh/TabbedDialog";
import { SlingElementProps } from "aem/ui/sling";
import React from "react";

export type ContainerDialogProps = Omit<SlingElementProps, "tagName"> &
  TabbedDialogProps;

export type ContainerDialogFC = React.FC<ContainerDialogProps>;

/**
 * The top-level element for author dialogs of container elements ( elements that may have children ).
 */
export const ContainerDialog: ContainerDialogFC = ({ children, ...rest }) => {
  return (
    <TabbedDialog {...rest}>
      {/*
      This is to hide the "Properties" tab inherited from "core/wcm/components/container/v1/container"
      which is the supertype of any container element we are defining for the project.
      */}
      <HideResource tagName="properties" />
      {children}
    </TabbedDialog>
  );
};

ContainerDialog.defaultProps = {};

export default ContainerDialog;
