import { FormFieldSet } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/fieldset/FormFieldSet";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type FieldSetProps = Omit<
  Omit<SlingElementProps, "tagName">,
  "jcr_title"
> & {
  title: string;
};

export type FieldSetFC = React.FC<FieldSetProps>;

export const FieldSet: FieldSetFC = ({ children, title, ...rest }) => {
  return (
    <FormFieldSet
      {...rest}
      jcr_title={title}
      tagName={rest["tagName"] || toTagName(title)}
    >
      {children}
    </FormFieldSet>
  );
};

FieldSet.defaultProps = {};

export default FieldSet;
