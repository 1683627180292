import {
  FormRadioGroup,
  FormRadioGroupProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/radiogroup/FormRadioGroup";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type RadioGroupProps = Omit<SlingElementProps, "tagName"> &
  FormRadioGroupProps;

export type RadioGroupFC = React.FC<RadioGroupProps>;

export const RadioGroup: RadioGroupFC = ({ children, name, ...rest }) => {
  return (
    <FormRadioGroup
      {...rest}
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      {children}
    </FormRadioGroup>
  );
};

RadioGroup.defaultProps = {};

export default RadioGroup;
