import { processResourcePath } from "@RHCommerceDev/aem/processPaths";
//Add Global AEM models to this object, they will be processed and added to the main GraphQL query.
const globalModelPaths = {
  footerLinks: "/admin/footer-links",
  hamburgerNav: "/admin/hamburger-nav",
  emailSignupContent: "/admin/marketing-email-popup",
  cookieModal: "/admin/cookie-modal"
};
export const globalModels = globalModelPaths;

const paths = {};
Object.entries(globalModels).forEach(([key, value]) => {
  paths[key] = processResourcePath(value);
});
export const globalPaths: any = paths;
export default globalModelPaths;
