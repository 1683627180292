import {
  FormRadioGroupItem,
  FormRadioGroupItemProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/radiogroup/FormRadioGroupItem";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type RadioProps = Omit<SlingElementProps, "tagName"> &
  FormRadioGroupItemProps;

export type RadioFC = React.FC<RadioProps>;

export const Radio: RadioFC = ({ children, value, ...rest }) => {
  return (
    <FormRadioGroupItem
      {...rest}
      tagName={rest["tagName"] || toTagName(`option-${value}`)}
      value={value}
    >
      {children}
    </FormRadioGroupItem>
  );
};

Radio.defaultProps = {};

export default Radio;
