import {
  Container as GraniteContainer,
  ContainerProps as GraniteContainerProps
} from "aem/ui/authoring/granite/ui/components/foundation/container/Container";
import { SlingElementProps } from "aem/ui/sling";
import React from "react";

export type ContainerProps = Omit<SlingElementProps, "tagName"> &
  GraniteContainerProps;

export type ContainerFC = React.FC<ContainerProps>;

export const Container: ContainerFC = ({ children, tagName, ...rest }) => {
  return (
    <GraniteContainer tagName={tagName} {...rest}>
      {children}
    </GraniteContainer>
  );
};

Container.defaultProps = {
  tagName: "container"
};

export default Container;
