import { Dialog, DialogProps } from "aem/ui/authoring/rh/Dialog";
import { Items } from "aem/ui/authoring/rh/Items";
import { Tabs } from "aem/ui/authoring/rh/Tabs";
import { SlingElementProps } from "aem/ui/sling";
import React from "react";

export type TabbedDialogProps = Omit<SlingElementProps, "tagName"> &
  DialogProps & {
    title: string;
  };

export type TabbedDialogFC = React.FC<TabbedDialogProps>;

export const TabbedDialog: TabbedDialogFC = ({ children, ...rest }) => {
  return (
    <Dialog {...rest}>
      <Tabs>
        <Items>{children}</Items>
      </Tabs>
    </Dialog>
  );
};

TabbedDialog.defaultProps = {};

export default TabbedDialog;
