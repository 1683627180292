/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A field that allows the user to enter path.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/pathfield/index.html
 */
export type FormTextFieldProps = FormFieldProps & {
  /**
   * The name that identifies the field when submitting the form.
   */
  name?: string;
  /**
   * A hint to the user of what can be entered in the field.
   * @attributes i18n
   */
  emptyText?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * Indicates if the user is able to select multiple selections.
   */
  multiple?: boolean;
  /**
   * Indicates if the user must only select from the list of given options.
   * If it is not forced, the user can enter arbitrary value.
   */
  forceSelection?: boolean;
  /**
   * true to generate the SlingPostServlet @Delete hidden input based on the field name.
   */
  deleteHint?: boolean;
  /**
   * The URI template that returns the suggestion markup.
   * It supports the following variables:
   * query
   * The query entered by the user.
   * offset
   * The offset of the pagination.
   * limit
   * The limit of the pagination.
   */
  suggestionSrc?: string;
  /**
   * The URI template that returns the picker markup.
   * It supports the following variables:
   * value
   * The value of the first item.
   */
  pickerSrc?: string;
  /**
   * The path of the root of the pathfield.
   * @attributes mandatory
   */
  rootPath: string;
  /**
   * The filter applied to suggestion and picker.
   * Valid values are:
   * folder
   * Shows only nt:folder nodes.
   * hierarchy
   * Shows only nt:hierarchyNode nodes.
   * hierarchyNotFile
   * Shows only nt:hierarchyNode nodes that are not nt:file.
   * nosystem
   * Shows non-system nodes: !node.getName().startsWith("rep:") && !node.getName().equals("jcr:system").
   * @attributes mandatory
   */
  filter: "folder" | "hierarchy" | "hierarchyNotFile" | "nosystem";
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormTextFieldFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormTextFieldProps
>;

export const FormTextField: FormTextFieldFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/pathfield"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormTextField.defaultProps = {
  deleteHint: true,
  rootPath: "/",
  filter: "hierarchyNotFile"
};
