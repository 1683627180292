import { Container } from "@adobe/aem-react-editable-components";
import React, { ComponentType } from "react";

// TODO: find more specific type for Props than React.FC<any>
const ContainerWrapper = (
  Component: React.FC<any>,
  ref?: string
): ComponentType<any> =>
  class RHContainer extends Container<any, any> {
    render() {
      return (
        <Component
          {...Object.assign({}, this.props, {
            containerProps: this.containerProps,
            placeholderComponent: this.placeholderComponent,
            childComponents: this.childComponents
          })}
        >
          {this.childComponents}
        </Component>
      );
    }
  };

export default ContainerWrapper;
