/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A field that allows user to enter number.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/numberfield/index.html
 */
export type FormNumberFieldProps = FormFieldProps & {
  /**
   * The name that identifies the field when submitting the form.
   * The SlingPostServlet @TypeHint hidden input with value matching typeHint property is also generated based on the name.
   */
  name?: string;
  /**
   * The value of the field.
   */
  value?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * The value of SlingPostServlet @TypeHint.
   */
  typeHint?: string;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * The minimum value allowed.
   */
  min?: number;
  /**
   * The maximum value allowed.
   */
  max?: number;
  /**
   * The increment/decrement step amount.
   * number
   * A double that is greater than zero.
   * In this case, the field accepts only values at multiples of the step value greater than the minimum.
   * "any"
   * The value can be in any number.
   */
  step?: string;
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormNumberFieldFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormNumberFieldProps
>;

export const FormNumberField: FormNumberFieldFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/numberfield"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormNumberField.defaultProps = {
  step: "1"
};
