import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";
import { Content } from "./Content";
import { Items } from "./Items";
import UniqueId from "./UniqueId";

export type DialogProps = Omit<SlingElementProps, "tagName"> & {
  title: string;
};

export type DialogFC = React.FC<DialogProps>;

export const Dialog: DialogFC = ({ children, title, helpPath }) => {
  return (
    <SlingElement
      tagName="jcr:root"
      jcr_title={title}
      helpPath={helpPath}
      sling_resourceType="cq/gui/components/authoring/dialog"
      xmlns_cq="http://www.day.com/jcr/cq/1.0"
      xmlns_granite="http://www.adobe.com/jcr/granite/1.0"
      xmlns_jcr="http://www.jcp.org/jcr/1.0"
      xmlns_nt="http://www.jcp.org/jcr/nt/1.0"
      xmlns_sling="http://sling.apache.org/jcr/sling/1.0"
    >
      <Content>
        <Items>
          <UniqueId />

          {children}
        </Items>
      </Content>
    </SlingElement>
  );
};

Dialog.defaultProps = {};

export default Dialog;
