import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";
import { SlingElement, SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type UniqueIdProps = Omit<SlingElementProps, "tagName"> & FormFieldProps;

export type UniqueIdFC = React.FC<UniqueIdProps>;

export const UniqueId: UniqueIdFC = ({ children, name, ...rest }) => {
  return (
    <SlingElement
      {...rest}
      sling_resourceType="rh/fields/uniqueid"
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      {children}
    </SlingElement>
  );
};

UniqueId.defaultProps = {
  name: "./rhUid"
};

export default UniqueId;
