import { toString, toTagName } from "aem/ui/sling/utils";
import React from "react";
import DropDown, { DropDownProps } from "./DropDown";
import DropDownItem from "./DropDownItem";
import Items from "./Items";

export type CheckBoxProps = DropDownProps & {
  /**
   * The text to display when checked ( default Yes ).
   */
  label?: string;
  /**
   * The text to display when unchecked ( default No ).
   */
  uncheckedLabel?: string;
  /**
   * The value to use when unchecked ( default {Boolean}false ).
   */
  uncheckedValue?: string;
  /**
   * The value to use when checked ( default {Boolean}true ).
   */
  value?: string;

  /**
   * first display No, and after Yes
   */
  reversed?: boolean;
};

export type CheckBoxFC = React.FC<CheckBoxProps>;

/**
 * Using a DropDown for rendering the CheckBox element.
 * The native AEM checkbox is buggy and doesn't work well with default values.
 * This DropDown approach also has the advantage of customizing the labels for positive and negative options.
 */
export const CheckBox: CheckBoxFC = ({
  children,
  name,
  tagName,
  typeHint,
  label,
  uncheckedLabel,
  uncheckedValue,
  value,
  reversed,
  ...rest
}) => {
  const myTagName = tagName || toTagName(name);

  const optionA = [label || "Yes", value || toString(true)];
  const optionB = [uncheckedLabel || "No", uncheckedValue || toString(false)];
  const options = reversed ? [optionB, optionA] : [optionA, optionB];

  return (
    <>
      <DropDown
        {...rest}
        name={name}
        tagName={myTagName}
        typeHint={typeHint || "Boolean"}
      >
        <Items>
          {options.map(([text, value]) => (
            <DropDownItem text={text} value={value!} />
          ))}
        </Items>
      </DropDown>
    </>
  );
};

CheckBox.defaultProps = {
  label: "Yes",
  typeHint: "Boolean",
  uncheckedLabel: "No",
  uncheckedValue: toString(false),
  value: toString(true)
};

export default CheckBox;
