/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";
import { ContainerProps } from "aem/ui/authoring/granite/Container";

/**
 * Select is a component to represent a concept of selection of some options.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/select/index.html
 */
export type FormSelectProps = FormFieldProps &
  ContainerProps & {
    /**
     * The name that identifies the field when submitting the form.
     */
    name?: string;
    /**
     * The initial text to display when nothing is selected.
     * @attributes i18n
     */
    emptyText?: string;
    /**
     * Indicates if the field is in disabled state.
     */
    disabled?: boolean;
    /**
     * Indicates if the field is mandatory to be filled.
     */
    required?: boolean;
    /**
     * The name of the validator to be applied. E.g. foundation.jcr.name.
     * See validation in Granite UI.
     * @attributes multiple
     */
    validation?: string;
    /**
     * Indicates if the user is able to select multiple selections.
     */
    multiple?: boolean;
    /**
     * true to translate the options, false otherwise.
     */
    translateOptions?: boolean;
    /**
     * true to sort the options based on the text, false otherwise.
     * It is assumed that the options don’t contain option group.
     */
    ordered?: boolean;
    /**
     * true to also add an empty option; false otherwise.
     * Empty option is an option having both value and text equal to empty string.
     */
    emptyOption?: boolean;
    /**
     * The variant of the select.
     */
    variant?: "default" | "quiet";
    /**
     * true to generate the SlingPostServlet @Delete hidden input based on the field name.
     */
    deleteHint?: boolean;
    /**
     * true to force to be ignore-freshness specifically just for this field.
     * This property is useful when you have a newly introduced field in the form, and there is a need to specifically set the default selected item.
     * To set the default selected item, set the selected property of the item as usual.
     * See nameNotFoundMode property of Field.
     */
    forceIgnoreFreshness?: boolean;
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type FormSelectFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormSelectProps
>;

export const FormSelect: FormSelectFC = ({ tagName, children, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/select"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormSelect.defaultProps = {
  translateOptions: true,
  ordered: false,
  emptyOption: false,
  variant: "default",
  deleteHint: true
};
