import {
  FormTextField,
  FormTextFieldProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/pathfield/FormTextField";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type PathPickerProps = Omit<SlingElementProps, "tagName"> &
  FormTextFieldProps;

export type PathPickerFC = React.FC<PathPickerProps>;

export const PathPicker: PathPickerFC = ({ children, name, ...rest }) => {
  return (
    <FormTextField
      {...rest}
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      {children}
    </FormTextField>
  );
};

PathPicker.defaultProps = {
  rootPath: "/content"
};

export default PathPicker;
