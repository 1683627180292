import {
  MapTo,
  Page,
  PageProperties,
  withModel
} from "@adobe/aem-react-editable-components";
import { PageModel } from "@RHCommerceDev/aem/types/pageModel";
import React, { memo } from "react";
import isEqual from "react-fast-compare";
import Helmet from "react-helmet";
import StaggerLoad from "aem/StaggerLoad";
import { processEnvServer } from "hooks/useSsrHooks";
import { getReqContext } from "utils/reqContext";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { getClientOrigin } from "utils/getClientOrigin";

export interface AEMPublishPageProps extends PageProperties {
  pageModel: PageModel;
  isAsync?: boolean;
  homeSchema?: string;
}

export class AEMUnmappedPublishPage extends Page<AEMPublishPageProps, any> {
  //TODO: remove once validation of async impl is complete.
  // shouldComponentUpdate() {
  //   // TODO: what was the case here?
  //   return false;
  // }

  componentDidMount() {
    this.updateMetaTags();
  }

  updateMetaTag = (selector: string, content: string | null) => {
    const element = document.querySelector(selector);
    if (element && content) {
      element.setAttribute("content", content);
    }
  };

  updateMetaTags = () => {
    const { pageModel, isAsync } = this.props;
    const path = processEnvServer ? getReqContext()?.path : location.pathname;
    const hostname = processEnvServer
      ? getClientOrigin()
      : window.location.hostname;
    const baseURL = `https://${hostname}`;
    const env = useEnv();

    if (!isAsync) {
      const title = pageModel?.pageTitle || (pageModel as any)?.title;
      const description =
        pageModel?.pageDesc || (pageModel as any)?.description;

      if (title) document.title = title;

      // Update meta tags
      this.updateMetaTag('meta[name="pageTitle"]', title);
      this.updateMetaTag('meta[property="og:title"]', title);
      this.updateMetaTag(
        'meta[property="og:image"]',
        "https://media.restorationhardware.com/is/image/rhis/1200x630_RH_Open_Graph_Image?wid=1000&fmt=jpeg"
      );
      this.updateMetaTag('meta[property="og:url"]', `${baseURL}${path}`);
      this.updateMetaTag('meta[name="description"]', description);
      this.updateMetaTag('meta[property="og:description"]', description);
      this.updateMetaTag('meta[name="pageDescription"]', description);

      // Update canonical link
      const pagePath = pageModel[":path"] || (pageModel as any)?.path;
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (pagePath && yn(env.FEATURE_CANONICAL_URLS) && canonicalLink) {
        canonicalLink.setAttribute("href", `${baseURL}${path}`);
      } else {
        canonicalLink?.remove();
      }
    }
  };

  render() {
    const req = getReqContext();
    const path = processEnvServer ? (req as any).path : location.pathname;
    //todo: replace this with a property in AEM to control this
    const overridePages = ["our-company", "customer-experience"];
    const isOverridePage = overridePages.some(page => {
      return path.indexOf(page) > -1;
    });
    const { pageModel, isAsync, homeSchema } = this.props;

    const pageTitleMetaTag = document.querySelector(
      'meta[name="pageTitle"]'
    ) as HTMLMetaElement;
    const ogTitle = document.querySelector(
      'meta[property="og:title"]'
    ) as HTMLMetaElement;
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    const ogDescription = document.querySelector(
      'meta[property="og:description"]'
    ) as HTMLMetaElement;
    const pageDescriptionMetaTag = document.querySelector(
      'meta[name="pageDescription"]'
    ) as HTMLMetaElement;

    const title = pageModel?.pageTitle || (pageModel as any)?.title;
    const description = pageModel?.pageDesc || (pageModel as any)?.description;

    return (
      <>
        {!isAsync && (
          <Helmet>
            {homeSchema && (
              <script type="application/ld+json" id="home-schema">
                {this.props.homeSchema}
              </script>
            )}
            {!pageTitleMetaTag && title && (
              <meta name="pageTitle" content={title} />
            )}
            {!ogTitle && title && <meta property="og:title" content={title} />}
            {!descriptionMetaTag && description && (
              <meta name="description" content={description} />
            )}
            {!ogDescription && description && (
              <meta property="og:description" content={description} />
            )}
            {!pageDescriptionMetaTag && description && (
              <meta name="pageDescription" content={description} />
            )}
          </Helmet>
        )}

        <div
          className={pageModel?.cssClassNames}
          style={{ backgroundColor: pageModel?.rhProps?.bgColor }}
        >
          {!isAsync && !isOverridePage && (
            <StaggerLoad childComponents={this.childComponents} />
          )}
          {(isAsync || isOverridePage) && this.childComponents}
        </div>
      </>
    );
  }
}

export const AEMPublishPage = MapTo<AEMPublishPageProps>(
  "rh/components/structure/page"
)(withModel(AEMUnmappedPublishPage));

export const AEMMemoizedPublishPage = memo(AEMPublishPage, isEqual);

export default AEMMemoizedPublishPage;
