/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { CommonAttrsProps } from "aem/ui/authoring/granite/CommonAttrs";
import { RenderConditionProps } from "aem/ui/authoring/granite/RenderCondition";
import { ContainerProps } from "aem/ui/authoring/granite/Container";

/**
 * The tab panel.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/tabs/index.html
 */
export type TabsProps = CommonAttrsProps &
  RenderConditionProps &
  ContainerProps & {
    /**
     * The orientation of the tabs.
     */
    orientation?: "horizontal" | "vertical";
    /**
     * The size of the tabs.
     */
    size?: "M" | "L";
    /**
     * Put vertical margin to the root element.
     */
    margin?: boolean;
    /**
     * Make the element maximized to fill the available space.
     */
    maximized?: boolean;
    /**
     * The name of the feature that the interaction takes place.
     * See foundation-tracking.
     */
    trackingFeature?: string;
    /**
     * The name of the widget.
     * See foundation-tracking.
     */
    trackingWidgetName?: string;
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type TabsFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & TabsProps
>;

export const Tabs: TabsFC = ({ tagName, children, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/tabs"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

Tabs.defaultProps = {
  orientation: "horizontal",
  size: "M"
};
