import {
  DropDown,
  DropDownItem,
  Items,
  PathPicker,
  TextBox
} from "aem/ui/authoring/rh";
import { toTypePath } from "aem/ui/sling/utils";
import React, { FC } from "react";

export type LinkProps = {
  assetPath?: string;
  content: string | React.ReactNode;
  target?: "_self" | "_blank" | "_parent" | "_top";
  url?: string;
};

export const LinkFields: FC = () => (
  <>
    <TextBox
      fieldLabel="Text"
      name={toTypePath<LinkProps>(props => props.content)}
    />

    <PathPicker
      fieldDescription="Pick an AEM asset path"
      fieldLabel="Asset Path ( optional - to link to an AEM page )"
      filter="nosystem"
      name={toTypePath<LinkProps>(props => props.assetPath)}
      rootPath={PathPicker.defaultProps!.rootPath!}
    />

    <TextBox
      fieldDescription="Only URLs within the same domain are allowed ( e.g., /rooms )."
      fieldLabel="or custom URL path ( alternative to Asset Path field - optional )"
      name={toTypePath<LinkProps>(props => props.url)}
    />

    <DropDown
      fieldDescription="
        Defines where to open the linked document:
          _self ( the same window or tab );
          _blank ( in a new window or tab - useful for download of images, documents, etc. );
          _parent ( in the parent frame );
          _top ( in the whole body of the window ).
      "
      fieldLabel="Destination frame ( aka target - default _self )"
      name={toTypePath<LinkProps>(props => props.target)}
    >
      <Items>
        {["_self", "_blank", "_parent", "_top"].map(option => (
          <DropDownItem text={option} value={option} />
        ))}
      </Items>
    </DropDown>
  </>
);

LinkFields.defaultProps = {};

export default LinkFields;
