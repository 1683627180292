import { SlingElementProps } from "aem/ui/sling";
import React from "react";
import { PRODUCT_IMAGE_PRESETS } from "utils/constants";
import DropDown from "./DropDown";
import DropDownItem from "./DropDownItem";
import Items from "./Items";

export type ImagePresetProps = Omit<SlingElementProps, "tagName">;

export type ImagePresetFC = React.FC<ImagePresetProps>;

export const ImagePreset: ImagePresetFC = props => {
  return (
    <DropDown {...props}>
      <Items>
        <DropDownItem tagName="empty" text="" value="" />
        {Object.entries(PRODUCT_IMAGE_PRESETS).map(([key, value]) => (
          <DropDownItem text={`${key} - ${value}`} value={key} />
        ))}
      </Items>
    </DropDown>
  );
};

ImagePreset.defaultProps = {
  fieldDescription: "Select one of the built-in Scene7 image presets",
  fieldLabel: "Built-in Scene7 Preset"
};

export default ImagePreset;
