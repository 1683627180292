import {
  Tabs as GraniteTabs,
  TabsProps as GraniteTabsProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/tabs/Tabs";
import { SlingElementProps } from "aem/ui/sling";
import React from "react";

export type TabsProps = Omit<SlingElementProps, "tagName"> & GraniteTabsProps;

export type TabsFC = React.FC<TabsProps>;

export const Tabs: TabsFC = ({ children, tagName, ...rest }) => {
  return (
    <GraniteTabs tagName={tagName} {...rest}>
      {children}
    </GraniteTabs>
  );
};

Tabs.defaultProps = {
  tagName: "tabs"
};

export default Tabs;
