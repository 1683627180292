import {
  Container,
  ContainerProps
} from "aem/ui/authoring/granite/ui/components/foundation/container/Container";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";
import LazyContainer from "./LazyContainer";

export type TabProps = Omit<SlingElementProps, "tagName"> &
  ContainerProps & {
    lazy?: boolean;
    title: string;
  };

export type TabFC = React.FC<TabProps>;

export const Tab: TabFC = ({
  children,
  lazy = false,
  tagName,
  title,
  ...rest
}) => {
  const Parent = lazy ? LazyContainer : Container;

  return (
    <Parent
      {...rest}
      jcr_title={title}
      loadTrigger="tab"
      tagName={tagName || toTagName(title)}
    >
      {children}
    </Parent>
  );
};

Tab.defaultProps = {};

export default Tab;
