import {
  FormMultifield,
  FormMultifieldProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/multifield/FormMultifield";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type MultifieldProps = Omit<SlingElementProps, "tagName"> &
  FormMultifieldProps;

export type MultifieldFC = React.FC<MultifieldProps>;

export const Multifield: MultifieldFC = ({ children, name, ...rest }) => {
  return (
    <FormMultifield
      {...rest}
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      {children}
    </FormMultifield>
  );
};

Multifield.defaultProps = {};

export default Multifield;
