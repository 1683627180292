/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementFC,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * Multifield component allows to add/reorder/remove multiple instances of a field.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/multifield/index.html
 */
export type FormMultifieldNamedChildren = {
  /**
   * The actual field of the Multifield.
   */
  field?: SlingElementFC;
};

export type FormMultifieldProps = FormFieldProps & {
  /**
   * true to handle the form content value as composite.
   * Composite multifield supports nesting another multifield (composite or not). However, non-composite one doesn’t support nesting.
   * For example, given the name property of field is addresses, and the descendant fields have following name property values:
   * street1
   * street2
   * postcode
   * city/name
   * city/state
   * city/country/name
   * gps/lat
   * gps/long
   * it would save the following structure in the repository:
   * + addresses
   *   + item0
   *     - street1
   *     - street2
   *     - postcode
   *     + city
   *       - name
   *       - state
   *       + country
   *         - name
   *     + gps
   *       - lat
   *       - long
   *   + item1
   *     - street1
   *     - street2
   *     - postcode
   *     + city
   *       - name
   *       - state
   *       + country
   *         - name
   *     + gps
   *       - lat
   *       - long
   */
  composite?: boolean;
  /**
   * true to generate the SlingPostServlet @Delete hidden input based on the field name.
   * In case of a nested multifield (composite), only the deleteHint of the root multifield will be set.
   */
  deleteHint?: boolean;
  /**
   * The value of SlingPostServlet @TypeHint.
   */
  typeHint?: string;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * The children.
   */
  children?: FormMultifieldNamedChildren | SlingElementChildren;
};

export type FormMultifieldFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormMultifieldProps
>;

export const FormMultifield: FormMultifieldFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/multifield"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormMultifield.defaultProps = {
  composite: false,
  deleteHint: true
};
