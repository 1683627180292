import {
  FormSelect,
  FormSelectProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/select/FormSelect";
import { FormHidden } from "aem/ui/authoring/granite/ui/components/foundation/form/hidden/FormHidden";
import { SlingElementProps, SlingTypeHint } from "aem/ui/sling";
import { toString, toTagName } from "aem/ui/sling/utils";
import React from "react";

export type DropDownProps = Omit<SlingElementProps, "tagName"> &
  FormSelectProps & {
    defaultValue?: string;
    /**
     * The value of SlingPostServlet @TypeHint.
     */
    typeHint?: SlingTypeHint;
  };

export type DropDownFC = React.FC<DropDownProps>;

export const DropDown: DropDownFC = ({
  children,
  defaultValue,
  name,
  tagName,
  typeHint,
  ...rest
}) => {
  const myTagName = tagName || toTagName(name);

  return (
    <>
      <FormSelect {...rest} name={name} tagName={myTagName}>
        {children}
      </FormSelect>

      {typeHint && (
        <FormHidden
          name={`${name}@TypeHint`}
          tagName={`${myTagName}TypeHint`}
          value={typeHint}
        />
      )}

      {defaultValue && (
        <FormHidden
          name={`${name}@DefaultValue`}
          tagName={`${myTagName}DefaultValue`}
          value={defaultValue}
        />
      )}

      <FormHidden
        name={`${name}@UseDefaultWhenMissing`}
        tagName={`${myTagName}UseDefaultWhenMissing`}
        value={toString(true)}
      />
    </>
  );
};

DropDown.defaultProps = {};

export default DropDown;
