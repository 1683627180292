import { Dialog, DialogProps } from "aem/ui/authoring/rh/Dialog";
import { Items } from "aem/ui/authoring/rh/Items";
import { Tab } from "aem/ui/authoring/rh/Tab";
import { Tabs } from "aem/ui/authoring/rh/Tabs";
import { SlingElementProps } from "aem/ui/sling";
import React from "react";

export type StandaloneDialogTabProps = Omit<SlingElementProps, "tagName"> &
  DialogProps & {
    id: string;
    title: string;
  };

export type StandaloneDialogTabFC = React.FC<StandaloneDialogTabProps>;

/**
 * This component uses a dialog to "host" the standalone tab.
 * This approach allows the use of the "/mnt/override" service to allow the standalone
 * tab work seamlessly in terms of data, where all the fields it contains will just work
 * and will be properly filled with the required data from the subject resource.
 *
 * The script ( a custom granite adapter ) portion of this component ( at <location> )
 * will register an event listener to watch all the component dialogs.
 *
 * Whenever such a dialog opens, an ajax call retrieves the standalone tab, pretty much
 * like a regular dialog request. The tab is extracted from the ajax response
 * and moved to the component dialog.
 */
export const StandaloneDialogTab: StandaloneDialogTabFC = ({
  children,
  id,
  title,
  ...rest
}) => {
  return (
    <Dialog {...rest} title={`Anonymous dialog for loading the "${title}" tab`}>
      <Tabs>
        <Items>
          <Tab granite_id={id} title={title}>
            {children}
          </Tab>
        </Items>
      </Tabs>
    </Dialog>
  );
};

StandaloneDialogTab.defaultProps = {};

export default StandaloneDialogTab;
