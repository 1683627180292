/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A field component to upload file.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/fileupload/index.html
 */
export type FormFileUploadProps = FormFieldProps & {
  /**
   * The name that identifies the field when submitting the form.
   */
  name?: string;
  /**
   * A hint to the user of what can be entered in the field.
   * @attributes i18n
   */
  emptyText?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * Indicates if the field is mandatory to be filled.
   */
  required?: boolean;
  /**
   * true to upload the files asynchronously.
   */
  async?: boolean;
  /**
   * The name of the validator to be applied. E.g. foundation.jcr.name.
   * See validation in Granite UI.
   * @attributes multiple
   */
  validation?: string;
  /**
   * Indicates if multiple files can be uploaded.
   */
  multiple?: boolean;
  /**
   * true to make the upload starts automatically once the file is selected.
   */
  autoStart?: boolean;
  /**
   * The URL to upload the file.
   * This is only required when autoStart is true.
   */
  uploadUrl?: string;
  /**
   * The file size limit.
   */
  sizeLimit?: number;
  /**
   * The browse and selection filter for file selection. E.g: [“.png”,”.jpg”] or [“image/*”].
   * @attributes multiple
   */
  mimeTypes?: string;
  /**
   * The text of the button.
   * @attributes i18n
   */
  text?: string;
  /**
   * Visually hide the text.
   * It is RECOMMENDED that every button has a text for a11y purpose. Use this property to hide it visually, while still making it available for a11y.
   */
  hideText?: boolean;
  /**
   * The icon of the button.
   */
  icon?: string;
  /**
   * The size of the icon.
   */
  iconSize?: "XS" | "S" | "M" | "L";
  /**
   * The size of the button.
   */
  size?: "M" | "L";
  /**
   * The variant of the button.
   */
  variant?: "primary" | "warning" | "quiet" | "minimal" | "actionBar";
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormFileUploadFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormFileUploadProps
>;

export const FormFileUpload: FormFileUploadFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/fileupload"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormFileUpload.defaultProps = {
  iconSize: "S",
  size: "M"
};
