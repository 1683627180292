/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { CommonAttrsProps } from "aem/ui/authoring/granite/CommonAttrs";
import { RenderConditionProps } from "aem/ui/authoring/granite/RenderCondition";
import { ContainerProps as BaseContainerProps } from "aem/ui/authoring/granite/Container";

/**
 * A general purpose container component.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/foundation/container/index.html
 */
export type ContainerProps = CommonAttrsProps &
  RenderConditionProps &
  BaseContainerProps & {
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type ContainerFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & ContainerProps
>;

export const Container: ContainerFC = ({ tagName, children, ...rest }) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/foundation/container"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

Container.defaultProps = {};
