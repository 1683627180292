import {
  FormNumberField,
  FormNumberFieldProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/numberfield/FormNumberField";
import { FormHidden } from "aem/ui/authoring/granite/ui/components/foundation/form/hidden/FormHidden";
import { SlingElementProps } from "aem/ui/sling";
import { toString, toTagName } from "aem/ui/sling/utils";
import React from "react";

export type NumberBoxProps = Omit<SlingElementProps, "tagName"> &
  FormNumberFieldProps & {
    defaultValue?: number;
  };

export type NumberBoxFC = React.FC<NumberBoxProps>;

export const NumberBox: NumberBoxFC = ({
  children,
  name,
  tagName,
  typeHint,
  defaultValue,
  ...rest
}) => {
  const myTagName = tagName || toTagName(name);

  return (
    <>
      <FormNumberField
        {...rest}
        name={name}
        tagName={myTagName}
        typeHint={typeHint}
      >
        {children}
      </FormNumberField>

      <FormHidden
        name={`${name}@TypeHint`}
        tagName={`${myTagName}TypeHint`}
        value={typeHint || "Long"}
      />

      <FormHidden
        name={`${name}@DefaultValue`}
        tagName={`${myTagName}DefaultValue`}
        value={toString(defaultValue, typeHint)}
      />

      <FormHidden
        name={`${name}@UseDefaultWhenMissing`}
        tagName={`${myTagName}UseDefaultWhenMissing`}
        value={toString(false)}
      />
    </>
  );
};

NumberBox.defaultProps = {};

export default NumberBox;
