/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { CommonAttrsProps } from "aem/ui/authoring/granite/CommonAttrs";
import { RenderConditionProps } from "aem/ui/authoring/granite/RenderCondition";
import { ContainerProps } from "aem/ui/authoring/granite/Container";

/**
 * A fieldset component. It is often used to group a couple of fields logically.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/fieldset/index.html
 */
export type FormFieldSetProps = CommonAttrsProps &
  RenderConditionProps &
  ContainerProps & {
    /**
     * The legend of the field set.
     * @attributes i18n
     */
    jcr_title?: string;
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type FormFieldSetFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormFieldSetProps
>;

export const FormFieldSet: FormFieldSetFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/fieldset"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormFieldSet.defaultProps = {
  tagName: "fieldset"
};
