import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";
import { Optional } from "types";

export type ChildrenEditorProps = Optional<SlingElementProps, "tagName">;

export type ChildrenEditorFC = React.FC<ChildrenEditorProps>;

export const ChildrenEditor: ChildrenEditorFC = ({
  children,
  tagName = "children",
  ...rest
}) => {
  return (
    <SlingElement
      {...rest}
      jcr_primaryType="nt:unstructured"
      sling_resourceType="core/wcm/components/commons/editor/dialog/childreneditor/v1/childreneditor"
      tagName={tagName}
    >
      {children}
    </SlingElement>
  );
};

ChildrenEditor.defaultProps = {
  tagName: "children"
};

export default ChildrenEditor;
