/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";
import { ContainerProps } from "aem/ui/authoring/granite/Container";

/**
 * A container to group a set of radios.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/radiogroup/index.html
 */
export type FormRadioGroupProps = FormFieldProps &
  ContainerProps & {
    /**
     * The name that identifies each radio when submitting the form.
     */
    name?: string;
    /**
     * true to generate the SlingPostServlet @Delete hidden input based on the name.
     */
    deleteHint?: boolean;
    /**
     * Indicates if the field is mandatory to be filled.
     */
    required?: boolean;
    /**
     * The name of the validator to be applied. E.g. foundation.jcr.name.
     * See validation in Granite UI.
     * @attributes multiple
     */
    validation?: string;
    /**
     * If false, the checked status is based on matching the form values by name and value properties.
     * Otherwise, the form values are not matched, and the checked status of each item is based on its checked property.
     */
    ignoreData?: boolean;
    /**
     * true to list the radios vertically; false otherwise.
     */
    vertical?: boolean;
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type FormRadioGroupFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormRadioGroupProps
>;

export const FormRadioGroup: FormRadioGroupFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/radiogroup"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormRadioGroup.defaultProps = {
  deleteHint: true
};
