import React, { useEffect, useState } from "react";

export const StaggerLoad = props => {
  const [loaded, setLoaded] = useState(1);
  const [scroll, setScroll] = useState(0);
  const mainComponentArray = props.childComponents.filter(item => {
    return (
      item.props &&
      item.props.cqType &&
      item.props.cqType === "wcm/foundation/components/responsivegrid"
    );
  });
  const mainComponent = mainComponentArray[0];
  if (!mainComponent) {
    return <></>;
  }
  const mainProps = mainComponent.props;
  const mainCqItems = mainProps.cqItems;
  const mainGrid = mainCqItems.responsivegrid;
  const mainItemsOrder = mainGrid[":itemsOrder"];
  const isPriorityComponent = component => {
    return (
      component.search("modal") > -1 ||
      component.search("rhhtml") > -1 ||
      component.search("header") > -1
    );
  };
  const priorityComponents = mainItemsOrder.filter(item => {
    return isPriorityComponent(item);
  });
  const itemsOrder = mainItemsOrder.filter((item, index) => {
    if (
      !isPriorityComponent(item) &&
      index < loaded + priorityComponents.length + 1
    ) {
      return item;
    }
  });
  const loadItemsOrder = [...priorityComponents, ...itemsOrder];
  useEffect(() => {
    const handleScroll = event => {
      const scrollDown = scroll < window.scrollY;
      if (loaded > 0 && loaded < mainItemsOrder.length && scrollDown) {
        setLoaded(loaded + 1);
        setScroll(scroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loaded, scroll]);
  const loadGrid = { ...mainGrid, ":itemsOrder": loadItemsOrder };
  const loadCqItems = { ...mainCqItems, responsivegrid: loadGrid };
  const loadProps = { ...mainProps, cqItems: loadCqItems };
  const loadComponent = { ...mainComponent, props: loadProps };
  const components = [loadComponent];
  return <>{<div>{components}</div>}</>;
};

export default StaggerLoad;
