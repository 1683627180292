/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2018 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import { ModelClient } from "@adobe/aem-spa-page-model-manager";
import { fetchModel } from "aem/fetchModel";

//  const FETCH_CONFIG = {
//    headers: {
//      Authorization: "Basic YWRtaW46YWRtaW4=" // base64 encode admin:admin
//    }
//  };

/**
 * Custom ModelClient meant to demonstrate how to customize the request sent to the remote server
 */
export class CustomModelClient extends ModelClient {
  client: any;
  model: any;
  constructor({ client, model }) {
    super();
    this.client = client;
    this.model = model;
  }
  /**
   * Fetches a model using the given a resource path
   *
   * @param {string} modelPath - Path to the model
   * @return {*}
   */
  private _url: string;
  private _brand: string;
  private _isServer: boolean;

  setUrl(url) {
    this._url = url;
  }
  setBrand(brand) {
    this._brand = brand;
  }
  setIsServer(isServer) {
    this._isServer = isServer;
  }

  setModel(model) {
    this.model = model;
  }

  get url() {
    return this._url;
  }
  get brand() {
    return this._brand;
  }
  get isServer() {
    return this._isServer;
  }

  fetch(modelPath) {
    if (!modelPath) {
      let err = "Fetching model rejected for path: " + modelPath;
      return Promise.reject(new Error(err));
    }

    if (modelPath.includes("/.model.json")) {
      modelPath = modelPath.replace("/.model.json", "/home.model.json");
    }
    if (modelPath.includes(".jsp")) {
      return Promise.resolve({});
    }
    /*
    return fetchModel({
      path: modelPath.toLowerCase(),
      graphqlClient: this.client
    });*/
    return Promise.resolve(this.model);
  }

  fetchResource(url) {
    return fetchModel({ path: url.toLowerCase(), graphqlClient: this.client });
  }
}

export const getModelClient = ({ client, model = {} }) => {
  return new CustomModelClient({ client, model });
};
