import {
  FormFileUpload,
  FormFileUploadProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/fileupload/FormFileUpload";
import { SlingElementProps } from "aem/ui/sling";
import { toString, toTagName } from "aem/ui/sling/utils";
import React from "react";

export type DropZoneProps = Omit<SlingElementProps, "tagName"> &
  FormFileUploadProps & {
    assetType: "image" | "video";
    fileReferencePropPath: any;
    title?: string;
  };

const mimePerType: { [type: string]: string } = {
  image: "[image/gif,image/jpeg,image/png,image/svg+xml]",
  video: "[video/mp4,video/webm]"
};

const defaultTitlePerType: { [type: string]: string } = {
  image: "Drop your image asset here",
  video: "Drop your video asset here"
};

export type DropZoneFC = React.FC<
  Omit<SlingElementProps, "tagName"> & DropZoneProps
>;

export const DropZone: DropZoneFC = ({
  assetType,
  children,
  fileReferencePropPath,
  tagName,
  title,
  ...rest
}) => {
  return (
    <FormFileUpload
      {...rest}
      class={`cq-droptarget${rest["class"] ? ` ${rest["class"]}` : ""}`}
      // TODO: should "fileNameParameter" be always equals to "./fileName"?
      fileNameParameter="./fileName"
      fileReferenceParameter={fileReferencePropPath}
      mimeTypes={mimePerType[assetType]}
      // TODO: should "name" be always equals to "./file"?
      name="./file"
      sling_resourceType="cq/gui/components/authoring/dialog/fileupload"
      tagName={rest["tagName"] || toTagName(fileReferencePropPath)}
      title={title || defaultTitlePerType[assetType]}
    />
  );
};

DropZone.defaultProps = {
  autoStart: false,
  multiple: false,
  title: "Drop your asset here",
  // TODO: what "${suffix.path}" does?
  uploadUrl: "${suffix.path}",
  useHTML5: toString(true)
};

export default DropZone;
