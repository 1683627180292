import { createProxyForPathExtraction } from "./createProxyForPathExtraction";

export const pathKey = Symbol.for("path");

export const constPropsKey = Symbol.for("constProps");

export type AuthoringProps<Props> = {
  getPath(picker: (props: Props) => any): string;

  getValue<Value>(picker: (props: Props) => Value): Value;

  derive<IntermediateProps, ExtraProps>(
    picker: (props: Props) => IntermediateProps,
    extraProps: ExtraProps
  ): AuthoringProps<IntermediateProps & ExtraProps>;
};

export function createAuthoringProps<Props>(
  propPath: string = ".",
  extraProps: any = {}
): AuthoringProps<Props> {
  return {
    getPath(picker) {
      return picker(createProxyForPathExtraction(propPath)).toString();
    },

    getValue<Value>(picker: (props: Props) => Value) {
      return picker(extraProps);
    },

    derive<IntermediateProps, ExtraProps>(
      picker: (Props: Props) => IntermediateProps,
      extraProps: ExtraProps
    ) {
      return createAuthoringProps<IntermediateProps & ExtraProps>(
        this.getPath(picker),
        extraProps
      );
    }
  };
}
