import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type ContentProps = Omit<SlingElementProps, "tagName">;

export type ContentFC = React.FC<ContentProps>;

export const Content: ContentFC = ({ children }) => {
  return (
    <SlingElement
      tagName="content"
      sling_resourceType="granite/ui/components/foundation/container"
    >
      {children}
    </SlingElement>
  );
};

Content.defaultProps = {
  tagName: "content"
};

export default Content;
