import { getLocaleFromPath } from "@RHCommerceDev/utils/intlUtils";
import {
  getBrandFromHostname,
  getAemRootPath,
  isRootPath
} from "@RHCommerceDev/utils/aemPathUtils";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { LocationProviderContextProps } from "@RHCommerceDev/custom-providers/LocationProvider";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
let hostName = processEnvServer ? getClientOrigin() : window?.location?.origin;

export const processResourcePath = path => {
  const req = processEnvServer ? getReqContext() : false;
  const { location } = window;
  const { locale } = getLocaleFromPath(
    (req ? (req as any).path : location.pathname) || path
  ) || { locale: "/us/en/" };
  const folderPrefix =
    path.indexOf(locale) > -1
      ? ""
      : locale + (path.indexOf("admin") > -1 ? "" : "admin");
  const brand =
    (getBrandFromHostname(
      hostName
    ).toLowerCase() as LocationProviderContextProps["brand"]) || "rh";
  const aemPath = `${
    path.includes("/" + brand + "/") ? "" : brand
  }${folderPrefix}/${path}`
    .replace("content/", "")
    .replace(".jsp", "")
    .replace("//", "/")
    .replace(".model.json", "")
    .replace(".html", "")
    .toLowerCase();
  //getmodel needs leading slashes
  const modelPath = Array.from(aemPath)[0] === "/" ? aemPath : "/" + aemPath;
  return modelPath;
};

export const processModelPath = path => {
  const brand =
    (getBrandFromHostname(
      hostName
    ).toLowerCase() as LocationProviderContextProps["brand"]) || "rh";
  const { locale } = getLocaleFromPath(path.replace(".model.json", "")) || {
    locale: "/us/en/"
  };
  const aemPath = isRootPath(path.replace(".model.json", ""))
    ? getAemRootPath(brand, locale)
    : (path.includes("/" + brand + "/") &&
      !path.endsWith("/" + brand + "/") &&
      !path.endsWith("/rh/")
        ? path
        : `/${brand}/${path.includes(locale) ? "" : locale}${path}`
      )
        .replace("content/", "")
        .replace(".jsp", "")
        .replace("//", "/")
        .replace(".model.json", "")
        .replace(".html", "")
        .toLowerCase();
  //getmodel needs leading slashes
  const modelPath = (
    Array.from(aemPath)[0] === "/" ? aemPath : "/" + aemPath
  ).replace(/\/\/+/g, "/");
  return modelPath;
};
