import { ContainerProps } from "aem/ui/authoring/granite/Container";
import { SlingElement, SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";
import { Container } from "../Container";
import { Items } from "../Items";

export type LazyContainerProps = Omit<SlingElementProps, "tagName"> &
  ContainerProps & {
    loadTrigger: "tab";
  };

export type LazyContainerFC = React.FC<LazyContainerProps>;

export const LazyContainer: LazyContainerFC = ({
  children,
  loadTrigger,
  name,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="rh/fields/lazycontainer"
      {...rest}
      loadTrigger={loadTrigger}
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      <Items>
        <Container tagName="container">{children}</Container>
      </Items>
    </SlingElement>
  );
};

LazyContainer.defaultProps = {};

export default LazyContainer;
