import React from "react";
import { toString } from "./utils";

export type SlingElementChildren = React.ReactNode;

export type SlingPropTypes = { [key: string]: any };

export type SlingElementProps = SlingPropTypes & {
  /**
   * The tag name to be used.
   */
  tagName: string;

  /**
   * The children.
   */
  children?: SlingElementChildren;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area.
   */
  rhGridArea?: string;

  /**
   * The same as https://developer.mozilla.org/en-US/docs/Web/CSS/align-self.
   */
  rhAlignSelf?: string;
};

export type SlingElementFC = React.FC<Omit<SlingElementProps, "children">>;

export type SlingTypeHint =
  | "Long"
  | "Decimal"
  | "Boolean"
  | "Date"
  | string
  | undefined;

export const SlingElement: SlingElementFC = props => {
  const { tagName, children, ...rest } = props;

  if (!tagName) {
    throw new Error(
      `A sling element must have a tag name: ${JSON.stringify(props)}`
    );
  }

  const sanitizedProps = Object.entries(rest).reduce(
    (acc: SlingPropTypes, [key, value]) => {
      acc[key.replace(/_/g, ":")] = toString(value);
      return acc;
    },
    {}
  );

  return React.createElement(
    tagName,
    {
      "jcr:primaryType": "nt:unstructured",
      ...sanitizedProps
    },
    children
  );
};

SlingElement.defaultProps = {};
