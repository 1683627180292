import { SlingElement, SlingElementProps } from "aem/ui/sling";
import React from "react";

export type FieldProps = Omit<SlingElementProps, "tagName"> & {
  /**
   * The name that identifies the field when submitting the form.
   */
  name?: string;
};

export type FieldFC = React.FC<FieldProps>;

export const Field: FieldFC = ({ children, name, tagName }) => {
  return (
    <SlingElement
      tagName={tagName}
      name={name}
      sling_resourceType="granite/ui/components/foundation/container"
    >
      {children}
    </SlingElement>
  );
};

Field.defaultProps = {
  tagName: "field"
};

export default Field;
