import { createProxyForPathExtraction } from "aem/ui/authoring/utils";
import camelCase from "lodash.camelcase";
import { SlingTypeHint } from ".";

const parsers: { [key: string]: (value: string) => any } = {
  Boolean: (value: string) => JSON.parse(value),
  Long: (value: string) => Number(value),
  Decimal: (value: string) => Number(value),
  Date: (value: string) => {
    throw Error(`not implemented: ${value}`);
  }
};

const isArrayLike = (v: any) =>
  typeof v === "object" && "length" in v && "slice" in v;
const isBoolean = (v: any) => typeof v === "boolean";
const isDate = (v: any) => v instanceof Date;
const isInteger = (v: any) => typeof v === "number" && Math.trunc(v) === v;
const isNumber = (v: any) => typeof v === "number";

export function toPath(selectedPath: any): string {
  const path = selectedPath.toString();

  return path;
}

export function toTypePath<T>(pathPicker: (props: T) => any): string {
  const model = createProxyForPathExtraction();

  const path = toPath(pathPicker(model));

  return path;
}

/**
 * Convert from AEM Model Path to JSON ( lodash compatible ) format.
 */
export function toJsonPath(aemModelPath: string): string {
  // treatment for array segments
  const jsonPath = aemModelPath
    .replace(/(\d+)\/\.\//g, "[$1].")
    .replace(/\.?\//g, ".")
    .replace(/^\./g, "");

  return jsonPath;
}

export function parseWithHint(
  value: string,
  typeHint: SlingTypeHint
): string | undefined {
  if (typeof value === "undefined") {
    return undefined;
  }

  if (value === null) {
    return value;
  }

  if (typeHint && typeHint in parsers) {
    return parsers[typeHint](value);
  }

  return value;
}

export function toString(
  value: any,
  typeHint?: SlingTypeHint
): string | undefined {
  if (typeof value === "undefined") {
    return undefined;
  }
  if (typeHint) {
    return `{${typeHint}}${value}`;
  }
  if (isBoolean(value)) {
    return `{Boolean}${value}`;
  } else if (isNumber(value)) {
    if (isInteger(value)) {
      return `{Long}${value}`;
    } else {
      return `{Decimal}${value}`;
    }
  } else if (isDate(value)) {
    /*
    TODO:
      Not sure if this is the right approach.
      We might need to use moment js.
      More details on https://sling.apache.org/documentation/bundles/manipulating-content-the-slingpostservlet-servlets-post.html#typehint ( look for dates ).
    */
    return `{Date}${value.toISOString()}`;
  } else if (typeof value.toString === "function") {
    return value.toString();
  }
  return value;
}

export function typeName(value: any): string {
  if (typeof value === "boolean") {
    return "Boolean";
  } else if (typeof value === "number") {
    if (Math.trunc(value) - value) {
      return "Decimal";
    } else {
      // TODO: check the range to output int or long ?
      return "Long";
    }
  } else if (typeof value === "object") {
    if (value instanceof Date) {
      return "Date";
    }
    //-------------------------------------
    // TODO: not sure if this makes sense
    //-------------------------------------
    if (isArrayLike(value)) {
      if (typeof value[0] === "string") {
        return "String[]";
      }
    }
    //-------------------------------------
  }
  // TODO: Can "String" be considered the fallback? Or is there a "Any" type in sling?
  return "String";
}

export function toTagName(text: string | undefined) {
  return (
    camelCase(text?.replace(/[^a-z_0-9]/gi, "-").replace(/-+/g, "-")) ||
    "default"
  );
}
