import { SlingElement } from "aem/ui/sling";
import { toString } from "aem/ui/sling/utils";
import React from "react";

export type HideResourceProps = {
  tagName: string;
};

export type HideResourceFC = React.FC<HideResourceProps>;

export const HideResource: HideResourceFC = ({ tagName }) => {
  return <SlingElement tagName={tagName} sling_hideResource={toString(true)} />;
};

HideResource.defaultProps = {};

export default HideResource;
