import {
  FormTextField,
  FormTextFieldProps
} from "aem/ui/authoring/granite/ui/components/coral/foundation/form/textfield/FormTextField";
import { SlingElementProps } from "aem/ui/sling";
import { toTagName } from "aem/ui/sling/utils";
import React from "react";

export type TextBoxProps = Omit<SlingElementProps, "tagName"> &
  FormTextFieldProps;

export type TextBoxFC = React.FC<TextBoxProps>;

export const TextBox: TextBoxFC = ({ children, name, ...rest }) => {
  return (
    <FormTextField
      {...rest}
      name={name}
      tagName={rest["tagName"] || toTagName(name)}
    >
      {children}
    </FormTextField>
  );
};

TextBox.defaultProps = {};

export default TextBox;
