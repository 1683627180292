/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";

/**
 * A container to group a set of radios.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/radiogroup/index.html
 */
export type FormRadioGroupItemProps = FormFieldProps & {
  /**
   * The value of the field.
   */
  value?: string;
  /**
   * The description of the component.
   * @attributes i18n
   */
  fieldDescription?: string;
  /**
   * Indicates if the field is in disabled state.
   */
  disabled?: boolean;
  /**
   * true to pre-check this item, false otherwise.
   */
  checked?: boolean;
  /**
   * The text of the radio.
   * @attributes i18n
   */
  text?: string;
  /**
   * The class for the wrapper element.
   */
  wrapperClass?: string;
  /**
   * The children.
   */
  children?: SlingElementChildren;
};

export type FormRadioGroupItemFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormRadioGroupItemProps
>;

export const FormRadioGroupItem: FormRadioGroupItemFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/radiogroup"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormRadioGroupItem.defaultProps = {};
