import React from "react";
import { DropDown, DropDownItem, Items } from ".";
import { DropDownProps } from "./DropDown";

const DELIMITER = ":";

const FONT_WEIGHT_OPTIONS = [
  "bold",
  "bolder",
  "inherit",
  "lighter",
  "normal",
  "initial"
];

export function splitFont(
  fontInfo: string
): [string, string, string | number] | [] {
  if (typeof fontInfo !== "string") return [];
  let [fontFamily, fontStyle, fontWeight] = fontInfo.split(DELIMITER);
  if (fontWeight && !FONT_WEIGHT_OPTIONS.includes(fontWeight)) {
    return [fontFamily, fontStyle, Number.parseInt(fontWeight)];
  }
  return [fontFamily, fontStyle, fontWeight];
}

export type FontPicker2Props = DropDownProps;

export type FontPicker2FC = React.FC<FontPicker2Props>;

export const FontPicker2: FontPicker2FC = props => {
  return (
    <DropDown {...props}>
      <Items>
        {/* Caslon*/}
        <DropDownItem
          text="Caslon RH Thin"
          value="CaslonRHThin:normal:normal"
        />
        <DropDownItem
          text="Caslon RH Thin Italic"
          value="CaslonRHThin:italic:normal"
        />
        <DropDownItem
          text="Caslon RH Superfine"
          value="CaslonRH-Superfine:normal:normal"
        />
        <DropDownItem
          text="Caslon RH Superfine Italic"
          value="CaslonRH-Superfine:italic:normal"
        />

        {/* Baron sas */}

        {/* ultrahairline */}

        <DropDownItem
          text="Baron Sans UltraHairline"
          value="RHSansUltraHairline:normal:normal"
        />
        <DropDownItem
          text="Baron Sans UltraHairline Italic"
          value="RHSansUltraHairline:italic:normal"
        />

        {/* hairline */}
        <DropDownItem
          text="Baron Sans Hairline"
          value="RHSans-Hairline:normal:normal"
        />
        <DropDownItem
          text="Baron Sans Hairline Italic"
          value="RHSans-Hairline:italic:normal"
        />

        {/* UltraThin */}
        <DropDownItem
          text="Baron Sans UltraThin"
          value="RHSans-UltraThin:normal:normal"
        />
        <DropDownItem
          text="Baron Sans UltraThin Italic"
          value="RHSans-UltraThin:italic:normal"
        />

        {/* thin */}
        <DropDownItem text="Baron Sans Thin" value="RHSans:normal:100" />
        <DropDownItem text="Baron Sans Thin Italic" value="RHSans:italic:100" />

        {/* Roman */}
        <DropDownItem text="Baron Sans Roman" value="RHSans:normal:normal" />
        <DropDownItem
          text="Baron Sans Roman  Italic"
          value="RHSans:italic:normal"
        />

        {/* medium */}
        <DropDownItem text="Baron Sans Medium" value="RHSans:normal:500" />
        <DropDownItem
          text="Baron Sans Medium Italic"
          value="RHSans:italic:500"
        />

        {/* bold */}
        <DropDownItem
          text="Baron Sans Bold"
          value="RHSans-Bold:normal:normal"
        />
        <DropDownItem
          text="Baron Sans Bold Italic"
          value="RHSans-Bold:italic:normal"
        />
      </Items>
    </DropDown>
  );
};

FontPicker2.defaultProps = {
  fieldLabel: "Font Family"
};

export default FontPicker2;
