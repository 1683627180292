/*********************************************************
 * !!! THIS FILE WAS GENERATED BY AN AUTOMATION TOOL !!! *
 * !!! PLEASE DO NOT MODIFY THIS FILE MANUALLY       !!! *
 *********************************************************/

import React from "react";
import {
  SlingElement,
  SlingElementChildren,
  SlingElementProps
} from "aem/ui/sling";
import { FormFieldProps } from "aem/ui/authoring/granite/ui/components/coral/foundation/form/field/FormField";
import { RenderConditionProps } from "aem/ui/authoring/granite/RenderCondition";

/**
 * Select is a component to represent a concept of selection of some options.
 *
 * @see https://helpx.adobe.com/experience-manager/6-5/sites/developing/using/reference-materials/granite-ui/api/jcr_root/libs/granite/ui/components/coral/foundation/form/select/index.html
 */
export type FormSelectItemProps = FormFieldProps &
  RenderConditionProps & {
    /**
     * The value of the option.
     * @attributes mandatory
     */
    value: string;
    /**
     * Indicates if the option is in disabled state.
     */
    disabled?: boolean;
    /**
     * true to pre-select this option, false otherwise.
     */
    selected?: boolean;
    /**
     * The text of the option.
     * @attributes i18n
     */
    text?: string;
    /**
     * The icon of the option.
     */
    icon?: string;
    /**
     * The icon describing the status of the option.
     */
    statusIcon?: string;
    /**
     * The text describing the status.
     * It is RECOMMENDED that it is specified when statusIcon is also specified for a11y purpose.
     * @attributes i18n
     */
    statusText?: string;
    /**
     * The style of the status icon.
     */
    statusVariant?: "error" | "warning" | "success" | "help" | "info";
    /**
     * The children.
     */
    children?: SlingElementChildren;
  };

export type FormSelectItemFC = React.FC<
  Omit<SlingElementProps, "sling_resourceType"> & FormSelectItemProps
>;

export const FormSelectItem: FormSelectItemFC = ({
  tagName,
  children,
  ...rest
}) => {
  return (
    <SlingElement
      sling_resourceType="granite/ui/components/coral/foundation/form/select"
      tagName={tagName}
      {...rest}
    >
      {children}
    </SlingElement>
  );
};

FormSelectItem.defaultProps = {};
